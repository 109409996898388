@charset "utf-8";

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Light.ttf");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Regular.ttf");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Regular.ttf");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Medium.ttf");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-SemiBold.ttf");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Bold.ttf");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Bold.ttf");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: Poppins;
    src: url("./../assets/fonts/Poppins/Poppins-Italic.ttf");
    font-weight: normal;
    font-style: italic;
}

// Define defaults for each variable.
$base-font-family: Poppins, sans-serif;
$base-font-size: 14px !default;
$base-font-weight: 400 !default;
$small-font-size: $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit: 30px !default;

$text-color: #212121 !default;
$background-color: #FBFCFD !default;
$brand-color: #2196F3 !default;
$link-hover-color: #0069C0 !default;

$grey-color: #ADADAD !default;
$grey-color-light: #CCCCCC !default;
$grey-color-medium: #767676 !default;
$grey-color-dark: darken($grey-color, 25%) !default;

$table-text-align: left !default;

// Width of the content area
$content-width: 800px !default;

$on-palm: 600px !default;
$on-laptop: 800px !default;

// Use media queries like this:
// @include media-query($on-palm) {
//   .wrapper {
//     padding-right: $spacing-unit / 2;
//     padding-left: $spacing-unit / 2;
//   }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

@mixin relative-font-size($ratio) {
    font-size: $base-font-size * $ratio;
}

// Import partials.
@import "./../_sass/minima/base",
"./../_sass/minima/layout",
"./../_sass/minima/syntax-highlighting",
"./../_sass/nqry";
