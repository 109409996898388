@import "minima";

/* Add embeded list item numbering for table of contents */

.highlighter-rouge .highlight {
    background-color: #EAEBED;
    border-radius: 3px;
    border: 0;
}

code {
    background-color: #EAEBED;
    border-radius: 3px;
    border: 0;
    display: inline-block;
    padding: 1px 3px;
}

.post-content {

    h1 {
        counter-reset: h2counter;
    }

    h2 {
        counter-reset: h3counter;
    }

    h3 {
        counter-reset: h4counter;
    }

    h4 {
        counter-reset: h5counter;
    }

    h5 {
        counter-reset: h6counter;
    }

    h2:before {
        counter-increment: h2counter;
        content: counter(h2counter) ".\0000a0\0000a0";
    }

    h3:before {
        counter-increment: h3counter;
        content: counter(h2counter) "." counter(h3counter) ".\0000a0\0000a0";
    }

    h4:before {
        counter-increment: h4counter;
        content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) ".\0000a0\0000a0";
    }

    h5:before {
        counter-increment: h5counter;
        content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "." counter(h5counter) ".\0000a0\0000a0";
    }

    h6:before {
        counter-increment: h6counter;
        content: counter(h2counter) "." counter(h3counter) "." counter(h4counter) "." counter(h5counter) "." counter(h6counter) ".\0000a0\0000a0";
    }

}

.table-of-contents-panel {
    height: calc(100% - 130px);
    left: 0;
    margin-top: 100px;
    padding-top: 30px;
    position: fixed;

    h4 {
        padding: 0 10px;
    }

    .table-of-contents {
        height: calc(100% - 40px);
        overflow-y: auto;
        padding: 0 10px;
    }

    ol {
        list-style-type: none;
        counter-reset: item;
        margin: 0;
        padding: 0;
    }

    ol > li {
        display: table;
        counter-increment: item;
    }

    ol > li:before {
        content: counters(item, ".") ". ";
        display: table-cell;
        padding-right: 0.6em;
    }

    li ol > li {
        margin: 0;
    }

    li ol > li:before {
        content: counters(item, ".") " ";
    }

}