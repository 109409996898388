/**
 * Site header
 */
.site-header {
    margin-top: 25px;
    min-height: $spacing-unit * 1.865;

    // Positioning context for the mobile navigation icon
    position: relative;
}

.site-title {
    @include relative-font-size(1.625);
    font-weight: 300;
    line-height: $base-line-height * $base-font-size * 2.25;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.page-link:hover {
    color: $link-hover-color;
}

.button-2.page-link:hover {
    color: #FFFFFF;
    text-decoration: none;
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    @include relative-font-size(1.125);
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width: calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width: calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width: calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width: calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width: calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width: calc(100% - (#{$spacing-unit} / 2));
    }
}


/**
 * Page content
 */
.page-content {
    flex: 1;
    margin-top: 100px;
}

.page-heading {
    @include relative-font-size(2.5);
    font-weight: bold;
}

.post-list-heading {
    @include relative-font-size(1.75);
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: 50px;
    }
}

.post-meta {
    color: $grey-color-medium;
}

.post-link {
    display: block;
    @include relative-font-size(1.5);
}


/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    @include relative-font-size(2.625);
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        @include relative-font-size(2.25);
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        @include relative-font-size(2);

        @include media-query($on-laptop) {
            @include relative-font-size(1.75);
        }
    }

    h3 {
        @include relative-font-size(1.625);

        @include media-query($on-laptop) {
            @include relative-font-size(1.375);
        }
    }

    h4 {
        @include relative-font-size(1.25);

        @include media-query($on-laptop) {
            @include relative-font-size(1.125);
        }
    }
}
